import React from 'react';
import {
    Stack,
    Link,
    Flex,
    Spacer,
    HStack,
    Image
} from '@chakra-ui/react';
import MobileDrawer from './mobile_drawer'
import { useNavigate } from "react-router-dom";
import { SignInButton } from '../profile/signInButton.jsx';
import { SignOutButton } from '../profile/signOutButton.jsx';
import Settings from './settings.jsx';

const Navbar = ({role, isAuthenticated, account, authCallback}) => {

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }

    return (
	<>
        <Flex className="desktop-navbar sticky-nav" backgroundColor={'lightgray'} height={85} padding="0.5rem calc((100vw - 1000px) / 2)">
            <HStack  ml="10">
                <Image src="hph.png" h="40px" />
                <Link onClick={() => handleClick("/")} fontSize={24} _hover={false} alignSelf={"center"} fontWeight={'bold'}> HeartrAIt</Link>
            </HStack>
            <Spacer/>
            <Stack spacing={8} direction='row' mr="10" >
                ({ isAuthenticated && <Link onClick={() => handleClick("/w2r")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>W2R</Link> })
                ({ isAuthenticated && <Link onClick={() => handleClick("/slideshow")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Slideshow</Link> })
                ({ isAuthenticated && <Link onClick={() => handleClick("/categories")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Categories</Link> })
                <Stack spacing={1} direction='row' >
                    {isAuthenticated&&account!==undefined ? <SignOutButton account={account} callback={authCallback}/> : <SignInButton ml="0" callback={authCallback}/>}
                    ({ role===1 && <Settings/> })           
                </Stack>
            </Stack>
        </Flex>
        <Flex className="mobile-navbar sticky-nav" backgroundColor={'lightgray'} height={85} padding="0.5rem calc((100vw - 1000px) / 2)" wrap="nowrap" justifyContent="space-between">
            <HStack  ml="2">
                <Image src="hph.png" h="40px" />
                <Link onClick={() => handleClick("/")} fontSize={24} _hover={false} alignSelf={"center"} fontWeight={'bold'}> HeartrAIt</Link>
            </HStack>            <HStack>
                ({ isAuthenticated ? <MobileDrawer isAuthenticated={isAuthenticated} account={account===undefined?null:account} role={role} authCallback={authCallback} />  : <SignInButton ml="0" mr="5" callback={authCallback} /> })
            </HStack>
        </Flex>
	</>
    );
};

export default Navbar;
