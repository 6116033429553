import React, { useEffect, useState } from 'react';
import {
	VStack,
	Heading,
	Center,
	Flex,
    Grid,
} from '@chakra-ui/react'
import ListItem from './categoriesListItem.jsx'
import CategoryForm from './categoriesForm.jsx'
import Spinner from '../connection/spinner.jsx'
import NetworkError from '../connection/network_error.jsx';
import AccessForbidden from '../pages/accessforbidden.jsx'
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js';

export default function Categories({isAuthenticated, role, localAccountId}) {
	const [categories, setCategories] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);

    const doFetch = async () => {
		setIsLoaded(false);
		axios.get(apiConfig.webApi + "categories")
            .then(function (response) {
                setCategories(response.data);
                setIsLoaded(true);
            })
            .catch(function (error) {
                console.log(error);
                setCategories(undefined);
            });
	}

    const addCategory = (newCat) => {
        newCat.allPics = 0;
        newCat.newPics = 0;
        setCategories([...categories, newCat]);
        doFetch();
    }

    const deleteCategory = (cat) => {
        setCategories(categories.filter(c => c !== cat));
    }

    const updateCategory = (oldcat, newcat) => {
        var index = categories.indexOf(oldcat);
        newcat.newPics = oldcat.newPics;
        newcat.allPics = oldcat.allPics;
        if (index !== -1) {
            var tempCat = [...categories];
            tempCat.splice(index, 1, newcat);
            setCategories(tempCat);
        }
        doFetch();
    }

	// eslint-disable-next-line
	useEffect(() => { doFetch(); }, []);

	if(!isAuthenticated){
		return <AccessForbidden/>
	}else if(categories===undefined){
		return <NetworkError />
	}else if(!isLoaded){
		return <Spinner />
	}else{
		return (
			<>
			<Center ml="5" mr="5">
                <VStack mt="10" mb="10" id="categories">
                    <Flex justifyContent="space-between" w="100%">
                        <Heading mb="10" >Categories</Heading>
                        { role===1 && <CategoryForm callback={addCategory}/> }
                    </Flex>
                    <Grid id="desktopCategories" display="grid" gridGap={4} templateColumns={{ md: "repeat(2, 300px)", lg: "repeat(3, 300px)" }} >
                        {categories.map(cat => 
                            <ListItem role={role} key={"cat" + cat.id} category={cat} callback={deleteCategory} updateCallback={updateCategory} doFetch={doFetch} localAccountId={localAccountId} />
                        )}
                    </Grid>
                    <VStack id="mobileCategories">
                        {categories.map(cat => 
                                <ListItem role={role} key={"cat" + cat.id} category={cat} callback={deleteCategory} updateCallback={updateCategory} doFetch={doFetch} localAccountId={localAccountId} />
                            )}
                    </VStack>
                </VStack>
			</Center>
			</>)
	}
};
