import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    FormControl,
    FormLabel,
    useDisclosure,
    Text,
    Textarea
  } from '@chakra-ui/react'
import apiConfig from '../../config/apiConfig';
import axios from 'axios';

export default function CategoryForm({callback}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [catName, setCatName ] = useState('');
    const [descriptor, setDescriptor ] = useState('');
    const [emptyError, setEmptyError] = React.useState(false)
  
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const submitFunction = (event) => {
        event.preventDefault();
        if(!catName){
            setEmptyError(true);
        }else{
            setEmptyError(false);
            axios.post(apiConfig.webApi + 'categories', {
                name: catName,
                descriptor: descriptor
              })
              .then(function (response) {
                callback(response.data);
                onClose();
              })
              .catch(function (error) {
                console.log(error);
              });
        }
    };
  
    return (
      <>
        <Button onClick={onOpen} colorScheme='purple' mt="1" fontSize="sm">+ New category</Button>
        
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <form onSubmit={submitFunction}>
              <ModalHeader>Add category</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" id="catName" name="catName" ref={initialRef} onChange={(event) => setCatName(event.target.value)} />
                  { emptyError ? <Text textColor="red.400" fontSize="sm" p="1" pl="2">Name must not be empty.</Text> : null }
                </FormControl>

                <FormControl>
                  <FormLabel>Descriptor</FormLabel>
                  <Textarea type="text" id="descriptor" name="descriptor" minHeight="300px" onChange={(event) => setDescriptor(event.target.value)} />
                </FormControl>
              </ModalBody>
    
              <ModalFooter>
                <Button type="submit" colorScheme='purple' mr={3}>
                  Save
                </Button>
                <Button onClick={onClose} >Cancel</Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  }
