import React from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import apiConfig from "../../config/apiConfig.js";

export function SignInButton({ml, mr, callback, text}) {
  const toast = useToast();

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: codeResponse => {
      axios.post(apiConfig.tokenUrl, {
        client_id: apiConfig.client_id,
        client_secret: apiConfig.client_secret,
        grant_type: "authorization_code",
        code: codeResponse.code,
        redirect_uri: apiConfig.redirectUri
    }).then((tokenResponse) => {
      localStorage.setItem("accessToken", tokenResponse.data.access_token);
      localStorage.setItem("refreshToken", tokenResponse.data.refresh_token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenResponse.data.access_token;
      callback(true);
    }).catch((error) => console.error(error));

      
      
    },
    onError: () => toast({
      title: 'Error while trying to log in',
      description: "Please contact the administrator",
      status: 'error',
      duration: 2000,
      isClosable: true,
    })
  });

  return (
    <Button fontSize={18} ml={ml} mr={mr} colorScheme='purple' p="4" alignSelf="center" onClick={() => login()}>{text ? text : "Log in"}</Button>
  );
};
