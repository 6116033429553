import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    VStack,
    HStack,
    Text
  } from '@chakra-ui/react'
import apiConfig from '../../config/apiConfig';
import axios from 'axios';
import Spinner from '../connection/spinner.jsx';

export default function AssignMentsModal({categoryId, assignmentCount, callback}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [assigments, setAssignments ] = useState([]);
    const [isLoaded, setIsLoaded ] = useState(false);
  
    const initialRef = React.useRef(null)

    const addAssignment = (userId) => {
        axios.post(apiConfig.webApi + 'assignments', {
            userId: userId,
            categoryId: categoryId
          })
          .then(function () {
            callback();
            onClose();
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    const deleteAssignment = (userId) => {
        axios.delete(apiConfig.webApi + 'assignments/' + userId + '/' + categoryId)
          .then(function () {
            callback();
            onClose();
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    const doFetch = () => {
        setIsLoaded(false);
        axios.get(apiConfig.webApi + 'users/assignments/' + categoryId)
        .then((response) => {
            setAssignments(response.data);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => setIsLoaded(true));
    }
    
  
    return (
        <>
            <Button onClick={() => { onOpen(); doFetch(); }} colorScheme='purple' w="full">Manage assignments ({assignmentCount})</Button>
            
            <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={onClose}
            >
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader>Manage assignments</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    {isLoaded ? <VStack alignItems="flex-start">
                        {assigments.map(assigment => 
                            <HStack key={"ass" + assigment.userId}>
                                <Text>{assigment.userName}</Text>
                                {assigment.assigned ? <Button colorScheme='red' onClick={() => deleteAssignment(assigment.userId)}>Delete</Button> : <Button colorScheme='green' onClick={() => addAssignment(assigment.userId)}>Add</Button>}
                            </HStack>
                        )}
                    </VStack> : <Spinner />}
                </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
  }
