const apiConfig = {
    tokenUrl: "https://accounts.google.com/o/oauth2/token",
    refreshUrl: "https://www.googleapis.com/oauth2/v4/token",
    webApi: "https://heartraitapi.andai.hu/api/",
    imageroute: "https://heartraitapi.andai.hu/images/",
    redirectUri: "https://heartrait.andai.hu",
    client_id: "515493592402-eostrrsm041ekgmreg52clbitvnim83j.apps.googleusercontent.com",
    client_secret: "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"
  };

export default apiConfig;
