import React, {useState} from 'react';
import {
	VStack,
	Center,
	Image,
    Flex,
    IconButton,
    Button,
    HStack,
    useToast
} from '@chakra-ui/react'
import { FaHeart } from "react-icons/fa";
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js'
import { useLocation } from "react-router-dom";
import AccessForbidden from '../pages/accessforbidden.jsx';

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function W2R({isAuthenticated, localAccountId}) {
    const [imgSrc, setImgSrc] = useState("images/loading.jpg");
    const [image, setImage] = useState(undefined);
    const [images, setImages] = useState(undefined);
    const [first, setFirst] = useState(true);
    const [loading, setLoading] = useState(true);

    const toast = useToast();

    let query = useQuery();
	var categoryId = query.get("id");
    if(categoryId==null){
        categoryId = 0;
    }

    const preloadImage = (url) => {
        const img = document.createElement('img');
        img.src = url;
    };

    const getAll = () => {
        axios.get(apiConfig.webApi + "pictures/all/" + categoryId)
            .then(function (response) {
                let shuffled = response.data
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
                setImages(shuffled);
            })
            .catch(function (error) {
                setImages([]);
                console.log(error);
            });
    };

    const getNext = () => {
        var currentImage = images[0];
        var nextImage = images[1];
        setImages(images.filter(item => item!== currentImage));
        if(currentImage){
            setImgSrc(apiConfig.imageroute + currentImage.path);
            setImage(currentImage);
            var pic = currentImage;
            axios.get(apiConfig.webApi + "pictures/needed/" + pic.categoryId)
                .then(function(response) {
                    if(!!response.data){
                        toast({
                            title: 'Generation started',
                            description: "Please proceed, autogeneration in the background...",
                            status: 'success',
                            duration: 10000,
                            isClosable: true,
                        });
                        axios.get(apiConfig.webApi + "pictures/generate/" + pic.categoryId);
                    }
                });

            if(nextImage){
                const nextImageUrl = apiConfig.imageroute + nextImage.path;
                preloadImage(nextImageUrl);
            }
        }else{
            setImgSrc("images/uptodate.jpg");
            setImage({id: undefined});
        }
    };

    const sendRating = (rating) => {
        setLoading(true);
        if(image.id){
            axios.post(apiConfig.webApi + 'ratings', {
                userId: localAccountId,
                pictureId: image.id,
                value: rating
              })
              .then(() => {
                getNext();
              })
              .catch(function (error) {
                console.log(error);
              });
        }
    };

    if(first){
        getAll();
        setFirst(false);
    }

    if(!image && !!images){
        getNext();
    }

    if(isAuthenticated){
    return (
        <Center>
            <VStack my="10" mx="5" fontSize='xl' width="sm" spacing={4}>
                <Image src={imgSrc} onLoad={() => setLoading(false)} borderRadius="10px"/>
                ({imgSrc===`images/uptodate.jpg` ? undefined : <Flex w="full" justifyContent="space-between">
                    <IconButton colorScheme='purple' w="30%" onClick={() => sendRating(1)} isLoading={loading}><FaHeart color='pink'/></IconButton>
                    <IconButton colorScheme='purple' w="30%" onClick={() => sendRating(2)} isLoading={loading}><HStack><FaHeart color='pink'/><FaHeart color='pink'/></HStack></IconButton>
                    <IconButton colorScheme='purple' w="30%" onClick={() => sendRating(3)} isLoading={loading}><HStack><FaHeart color='pink'/><FaHeart color='pink'/><FaHeart color='pink'/></HStack></IconButton>
                </Flex>})
                ({imgSrc===`images/uptodate.jpg` ? undefined : <Button colorScheme='purple' w="100%" onClick={() => sendRating(0)} isLoading={loading}>It's neutral to me.</Button>})
            </VStack>
        </Center>
    );
    }else{
        return(<AccessForbidden />);
    }
};
