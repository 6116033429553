import React from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    IconButton
} from '@chakra-ui/react'
import { MdDelete } from "react-icons/md"
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js';

export default function DeleteConfirmation({cat, callback}){
    const deleteFunction = () => {
        axios.delete(apiConfig.webApi + "categories/" + cat.id)
            .then(() => {
              callback(cat);
              onClose();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

      const { isOpen, onOpen, onClose } = useDisclosure()
      const cancelRef = React.useRef()
    
      return (
        <>
          <IconButton w="10px" colorScheme='red' fontSize="sm" variant="outline" onClick={onOpen}><MdDelete /></IconButton>
    
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent width="90%">
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Confirm delete</AlertDialogHeader>
    
                <AlertDialogBody>
                  Do you wish to proceed?
                </AlertDialogBody>
    
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme='red' onClick={() => deleteFunction()} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )
};
