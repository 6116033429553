import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    useDisclosure,
    Stack,
    Link,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { useNavigate } from "react-router-dom";
import { SignInButton } from '../profile/signInButton.jsx';
import { SignOutButton } from '../profile/signOutButton.jsx';
import Settings from './settings.jsx';

export default function MobileDrawer({isAuthenticated, account, role, authCallback}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
        onClose();
    }

    return (
      <>
        <IconButton icon={<HamburgerIcon fontSize="3xl"/>} ref={btnRef} onClick={onOpen} backgroundColor="transparent" alignSelf="center" justifySelf="center" m="5" />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
                <Stack spacing={8} direction='column' mt="10" >
                    <Link onClick={() => handleClick("/")} fontSize={30} _hover={false} fontWeight={'bold'} >HeartrAIt</Link>
                    ({ isAuthenticated && <Link onClick={() => handleClick("/w2r")} fontSize={24} _hover={false} >W2R</Link> })
                    ({ isAuthenticated && <Link onClick={() => handleClick("/slideshow")} fontSize={24} _hover={false} >Slideshow</Link> })
                    ({ isAuthenticated && <Link onClick={() => handleClick("/Categories")} _hover={false} fontSize={24}>Categories</Link> })
                </Stack>
            </DrawerBody>
  
            <DrawerFooter>
              { role===1 && <Settings/> }
              {isAuthenticated ? <SignOutButton account={account} callback={authCallback}/> : <SignInButton ml="0" callback={authCallback}/>}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
}
