import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast,
    FormControl,
    FormLabel,
    Textarea,
    IconButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Checkbox,
    Flex,
    VStack,
    Center
  } from '@chakra-ui/react'
import { IoIosSettings } from 'react-icons/io';
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js';

export default function CategoriesEditor({category, updateCallback, doFetch}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [descriptor, setDescriptor] = useState(category.descriptor);
    const [trials, setTrials] = useState(category.trials);
    const [autogenerate, setAutogenerate] = useState(category.autoGenerate);
    const [demo, setDemo] = useState(category.demo);
    const [disabled, setDisabled] = useState(false);

    const generate = () => {
        toast({
            title: 'Generation started',
            description: "Please wait",
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
        onClose();
        axios.get(apiConfig.webApi + "pictures/generate/" + category.id)
            .then(() => {
                toast({
                    title: 'Generation completed',
                    description: "Check out the new pictures.",
                    status: 'success',
                    duration: 15000,
                    isClosable: true,
                });
                doFetch();
            })
            .catch(function (error) {
                toast({
                    title: 'Generation failed',
                    description: error,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            });
    };

    const updateCategory = (event) => {
        event.preventDefault();
        axios.put(apiConfig.webApi + 'categories/' + category.id, {
            id: category.id,
            name: category.name,
            descriptor: descriptor,
            trials: trials,
            autoGenerate: autogenerate,
            demo: demo
          })
          .then((response) => {
            updateCallback(category, response.data);
            setDisabled(false);
            onClose();
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    var autoCheckbox;
    if(autogenerate){
      autoCheckbox = <Checkbox size="lg" colorScheme="purple" mb="2" mt="5" onChange={(event) => { setAutogenerate(event.target.checked); setDisabled(true); }} defaultChecked>Autogenerate</Checkbox>;
    }else{
      autoCheckbox = <Checkbox size="lg" colorScheme="purple" mb="2" mt="5" onChange={(event) => { setAutogenerate(event.target.checked); setDisabled(true); }}>Autogenerate</Checkbox>;
    }

    var assignCheckbox;
    if(demo){
      assignCheckbox = <Checkbox size="lg" colorScheme="purple" mb="2" mt="5" onChange={(event) => { setDemo(event.target.checked); setDisabled(true); }} defaultChecked>Autoassign</Checkbox>;
    }else{
      assignCheckbox = <Checkbox size="lg" colorScheme="purple" mb="2" mt="5" onChange={(event) => { setDemo(event.target.checked); setDisabled(true); }}>Autoassign</Checkbox>;
    }

    return (
      <>
        <IconButton colorScheme='purple' w="full" onClick={onOpen}><IoIosSettings/></IconButton>
  
        <Modal isOpen={isOpen} onClose={onClose} w="90%" >
          <ModalOverlay />
          <ModalContent width="90%">
            <form onSubmit={updateCategory}>
                <ModalHeader>Generation Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center>
                    <Button isDisabled={!!disabled} colorScheme='purple' alignSelf="center" onClick={generate} mb="10">Generate</Button>
                  </Center>
                    <FormControl>
                      <Flex flexDir="row" wrap="wrap" alignItems="end" justifyContent="space-between">
                        <VStack alignItems="flex-start">
                          <FormLabel mb="0">#Trials</FormLabel>
                          <NumberInput step={1} w="100px" defaultValue={category.trials} min={0} max={30} onChange={(event) => { event && setTrials(event); setDisabled(true); }}>
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </VStack>
                        {autoCheckbox}
                        {assignCheckbox}
                      </Flex>
                      
                    </FormControl>
                    <FormControl mt="10">
                      <FormLabel>Descriptor</FormLabel>
                      <Textarea type="text" id="descriptor" name="descriptor" minHeight="200px" onChange={(event) => { setDescriptor(event.target.value); setDisabled(true); }} defaultValue={descriptor}/>
                    </FormControl>
                    
                </ModalBody>
    
                <ModalFooter>
                <Button mr={3} onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" colorScheme='purple' mr={3}>
                    Save
                </Button>
                </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
}