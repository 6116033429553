import React from "react";
import { Button } from "@chakra-ui/react";
import { googleLogout } from '@react-oauth/google';
import { IoIosLogOut } from "react-icons/io";

export const SignOutButton = ({account, callback}) => {  
  const handleLogout = () => {
    googleLogout();
    callback(false);
  };

  return (
    <Button fontSize={18} rightIcon={<IoIosLogOut />} colorScheme='purple' p="4" alignSelf="center" onClick={() => handleLogout()}>{account.given_name}</Button>
  );
};
