import React, {useState} from 'react';
import {
	VStack,
	Center,
	Image,
    Button,
    useToast,
    HStack,
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js'
import { useLocation, useNavigate } from "react-router-dom";
import AccessForbidden from '../pages/accessforbidden.jsx';

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SlideShow({isAuthenticated, localAccountId}) {
    const [imgSrc, setImgSrc] = useState("images/loading.jpg");
    const [image, setImage] = useState(undefined);
    const [images, setImages] = useState(undefined);
    const [first, setFirst] = useState(true);
    const [loading, setLoading] = useState(true);
    const [currentCounter, setCurrentCounter] = useState(0);

    let query = useQuery();
    let toast = useToast();
    const navigate = useNavigate();
	var categoryId = query.get("id");
    if(categoryId==null){
        categoryId = 0;
    }

    const preloadImage = (url) => {
        const img = document.createElement('img');
        img.src = url;
    };

    const getAll = () => {
        axios.get(apiConfig.webApi + "pictures/slideshow/" + categoryId)
            .then(function (response) {
                let shuffled = response.data
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
                setImages(shuffled);
            })
            .catch(function (error) {
                toast({ title: 'No pictures found', status: 'error', duration: 2000, isClosable: true });
                console.log(error);
                navigate('/categories');
            });
    };

    const picDisplay = (forward) => {
        let newIndex = forward ? currentCounter + 1 : currentCounter - 1;
        let nextIndex = forward ? currentCounter + 2 : currentCounter - 2;
        if (newIndex >= images.length) {
            newIndex = 0;
        }
        if (newIndex < 0) {
            newIndex = images.length - 1;
        }
        if (nextIndex >= images.length) {
            nextIndex = 0;
        }
        if (nextIndex < 0) {
            nextIndex = images.length - 1;
        }

        var currentImage = images[newIndex];
        var nextImage = images[nextIndex];
        
        setImgSrc(apiConfig.imageroute + currentImage.path);
        setImage(currentImage);
        if (nextImage) {
            const nextImageUrl = apiConfig.imageroute + nextImage.path;
            preloadImage(nextImageUrl);
        }

        setCurrentCounter(newIndex);
    };

    if (first) {
        getAll();
        setFirst(false);
    }

    if(!image && !!images){
        picDisplay(true);
    }

    if(isAuthenticated){
    return (
        <Center>
            <VStack my="10" mx="5" fontSize='xl' width="sm" spacing={4}>
                <Image src={imgSrc} onLoad={() => setLoading(false)} borderRadius="10px"/>
                ({imgSrc===`images/uptodate.jpg` ? undefined : <HStack w="100%" justifyContent="space-between">
                    <Button colorScheme='purple' w="49%" onClick={() => picDisplay(false)} isLoading={loading}>Back</Button>
                    <Button colorScheme='purple' w="49%" onClick={() => picDisplay(true)} isLoading={loading}>Next</Button>
                </HStack>})
            </VStack>
        </Center>
    );
    }else{
        return(<AccessForbidden />);
    }
};
