import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    IconButton,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    useDisclosure,
    HStack
  } from '@chakra-ui/react'
import apiConfig from '../../config/apiConfig';
import axios from 'axios';
import { IoIosSettings } from "react-icons/io";

export default function Settings() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [first, setFirst] = useState(true);
  
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const submitFunction = () => {
      axios.post(apiConfig.webApi + 'preferences', {
        key: key,
        value: value
      })
      .then(function (response) {
        onClose();
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    if(first){
      setFirst(false);
      axios.get(apiConfig.webApi + 'preferences')
      .then(function (response) {
        if (response.data.length > 0) {
          setKey(response.data[0].key);
          setValue(response.data[0].value);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  
    return (
      <>
        <IconButton onClick={onOpen} colorScheme='purple' p="2" m="2" alignSelf="center"><IoIosSettings size="sm"/></IconButton>
        
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
              <ModalHeader>Settings</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <HStack w="full">
                  <Input type="text" w="33%" value={key} ref={initialRef} onChange={(event) => setKey(event.target.value)} />
                  <Input type="text" w="33%" value={value} onChange={(event) => setValue(event.target.value)} />
                  <Button type="submit" w="33%" colorScheme='purple' mr={3} onClick={() => submitFunction()}>Save</Button>
                </HStack>
              </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }
