import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home.jsx'
import AccessForbidden from './components/pages/accessforbidden.jsx';
import NoPage from './components/pages/nopage.jsx';
import Navbar from './components/navbar/navbar';
import W2R from './components/w2r/w2r.jsx';
import Categories from './components/categories/categories.jsx';
import Pictures from './components/pictures/pictures.jsx';
import axios from 'axios';
import apiConfig from './config/apiConfig.js';
import { useToast } from '@chakra-ui/react';
import SlideShow from './components/w2r/slideshow.jsx';

function App() {
  const [role, setRole] = useState(0);
  const [account, setAccount] = useState({ name: undefined });
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [accountId, setAccountId] = useState();

  const toast = useToast();

  const authCallback = (param) => {
    if(param){
      axios.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json")
      .then((response) => {
        setIsAuthenticated(true);
        setAccount(response.data);
        setAccountId(account.id);

        axios.get(apiConfig.webApi + "users/me")
          .then((response) => {
            if(response.status!==401){
              setRole(response.data.role);
            }
          })
          .catch(() => {
            setIsAuthenticated(false);
            toast({
              title: 'Connection failed',
              description: 'Check the connection to the server',
              status: 'error',
              duration: 10000,
              isClosable: true,
          });
          });
      })
      .catch(() => {
        axios.post(apiConfig.refreshUrl, {
          client_id: apiConfig.client_id,
          client_secret: apiConfig.client_secret,
          refresh_token: localStorage.getItem("refreshToken"),
          grant_type: "refresh_token"
        }).then(response => {
          localStorage.setItem("accessToken", response.data.access_token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
          authCallback(true);
        }).catch(error => {
          console.error(error);
          setRole(0);
          setIsAuthenticated(false);
          setAccount({ name: undefined });
          setAccountId("");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        });
      });
    }else{
      setIsAuthenticated(false);
      setRole(-1);
      setAccount({ name: undefined });
      setAccountId("");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  };

  var accessToken = localStorage.getItem("accessToken");
  if(!accountId && accessToken){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    authCallback(true);
  }

  return (
    <Router>
      <Navbar isAuthenticated={isAuthenticated} role={role} account={account} authCallback={authCallback} />
        <Routes>
          <Route path='/' element={<Home isAuthenticated={isAuthenticated} callback={authCallback}/>} />
          <Route path='/w2r' element={<W2R isAuthenticated={isAuthenticated} role={role} localAccountId={accountId} />} />
          <Route path='/slideshow' element={<SlideShow isAuthenticated={isAuthenticated} role={role} localAccountId={accountId} />} />
          <Route path='/categories' element={<Categories isAuthenticated={isAuthenticated} role={role} localAccountId={accountId} />} />
          <Route path='/pictures' element={<Pictures isAuthenticated={isAuthenticated} role={role}/>} />
          <Route path="/forbidden" element={<AccessForbidden />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
    </Router>
  );
}

export default App;
