import React from 'react';
import {
    Flex,
    Text,
    Button,
    Divider,
    HStack
} from '@chakra-ui/react'
import DeleteConfirmation from './deleteConfirmer.jsx'
import { useNavigate } from "react-router-dom";
import CategoriesEditor from './categoriesEditor.jsx';
import AssignMentsModal from './assignmentsModal.jsx';
import GenLogsModal from './genLogsModal.jsx';
import { FaPlay } from "react-icons/fa";

export default function ListItem({role, category, callback, updateCallback, doFetch, localAccountId}){
    const navigate = useNavigate();

    const lines = category.descriptor.split('\n');
    let counter = -1;
    const idxs = lines.map((item) => {
        counter++;
        if(item==="@@@") return counter;
        else return -1;
    });
    const filtered = idxs.filter(item => item!==-1);
    counter = 0;
    const promptArray = filtered.map((item) => lines[item+1]);
    var builtPrompt = promptArray.toString().replaceAll(",", "");

    var border;
    if(category.assignments && category.assignments.filter(item => item.userId && item.userId===localAccountId).length > 0){
        border = "1px solid gray";
    }else{
        border = "";
    }

    return (
        <Flex bg='lightgray' border={border} p={4} gap="2" borderRadius={10} flexDir="column" alignItems="flex-start" id="catListItem">
            <Flex justifyContent="space-between" w="100%">
                <Text fontSize="lg" fontWeight="bold">{category.name}</Text>
                {role===1 && <HStack>
                    <Button colorScheme='purple' onClick={() => navigate("/slideshow?id=" + category.id)}><FaPlay/></Button>
                    <CategoriesEditor category={category} updateCallback={updateCallback} doFetch={doFetch}/>
                    <DeleteConfirmation cat={category} callback={callback}/>
                </HStack>}
            </Flex>
            {category.descriptor!==undefined && <Text>{builtPrompt}</Text>}
            { role===1 && <AssignMentsModal categoryId={category.id} assignmentCount={category.assignments ? category.assignments.length : 0 } callback={doFetch}/> }
            { role===1 && <GenLogsModal categoryId={category.id}/> }
            <Divider orientation='horizontal' border="1px solid black"/>
            <Flex dir='row' justifyContent="space-between" w="full">
                <Button colorScheme='purple' p='2' m='2' flexGrow="1" alignSelf="center" onClick={() => navigate("/pictures?id=" + category.id)}>All pics ({category.allPics})</Button>
                <Button colorScheme='purple' p='2' m='2' flexGrow="1" alignSelf="center" onClick={() => navigate("/w2r?id=" + category.id)}>New pics ({category.newPics})</Button>
            </Flex>
        </Flex>
    );
};
