import React from 'react';
import {
	VStack,
	StackDivider,
	Center,
	Text
} from '@chakra-ui/react'

export default function AccessForbidden() {
    return (
        <Center>
            <VStack my="10" mx="5" fontSize='xl' width="4xl" divider={<StackDivider borderColor='gold' />} spacing={4}>
                <Text>
                    Ennek az oldalnak a megtekintéséhez nincs jogosultságod
                </Text>
            </VStack>
        </Center>
    );
};