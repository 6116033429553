import React from 'react';
import {
	VStack,
	Heading,
	Center,
	Text,
	Image,
	Button
} from '@chakra-ui/react'
import { SignInButton } from '../profile/signInButton';
import { useNavigate } from "react-router-dom";

const Home = ({isAuthenticated, callback}) => {
	const navigate = useNavigate();

	return (
		<Center>
			<VStack my="5" mx="5" fontSize='xl' width="4xl">
				<Heading size='2xl' textAlign="center" my='2' >
					Welcome to HeartrAIt
				</Heading>

				<Center>
					<Image src="images/llamas.jpg" w="200px" h="200px" borderRadius="10" mx="1"/>
					<Image src="images/monkey.jpg" w="300px" h="300px" borderRadius="10" mx="1"/>
					<Image src="images/robot.jpg" w="200px" h="200px" borderRadius="10" mx="1"/>
				</Center>

				<Text mt="5" textAlign="center">
					Explore the world of AI generated pictures.
				</Text>
				<Text textAlign="center">
					Only a click from you.
				</Text>
				({ isAuthenticated ? <Button fontSize={18} colorScheme='purple' p="4" alignSelf="center" onClick={() => navigate('/w2r')}>Get Started</Button> : <SignInButton ml="0" mr="5" callback={callback} text="Get started"/> })
			</VStack>
		</Center>
	);
};

export default Home;