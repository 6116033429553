import React, {useState} from 'react';
import {
	VStack,
	Center,
    Flex,
    Heading,
    Grid
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiConfig.js'
import { useLocation } from "react-router-dom";
import DeleteConfirmation from './deleteConfirmer.jsx';
import AccessForbidden from '../pages/accessforbidden.jsx';

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Pictures({isAuthenticated, role}) {
    const [images, setImages] = useState([]);
    const [first, setFirst] = useState(true);

    let query = useQuery();
	var categoryId = query.get("id");
    if(categoryId==null){
        categoryId = 0;
    }

    const getAll = () => {
        axios.get(apiConfig.webApi + "pictures/" + categoryId)
            .then(function (response) {
                setImages(response.data);
            })
            .catch(function (error) {
                setImages([]);
                console.log(error);
            });
    };

    if(first){
        getAll();
        setFirst(false);
    }

    const deletePicture = (pic) => {
        setImages(images.filter(c => c !== pic));
    }

    const imageList = images.map(i => 
        <div className="container" key={i.path}>
            <img src={apiConfig.imageroute + i.path} className="img" alt="generated" />
            <div className="topright">
                { role===1 && <DeleteConfirmation pic={i} callback={deletePicture} />}
            </div>
        </div>);

    if(isAuthenticated){
    return (
        <>
			<Center ml="5" mr="5">
                <VStack mt="10" mb="10">
                    <Flex justifyContent="space-between" w="100%">
                        <Heading mb="10" >Pictures</Heading>
                    </Flex>
                    <Grid display="grid" gridGap={4} templateColumns={{ md: "repeat(2, 300px)", lg: "repeat(3, 300px)" }} >
                        {imageList}
                    </Grid>
                </VStack>
			</Center>
		</>);
    }else{
        return(<AccessForbidden />);
    }
};
